import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  inject,
} from '@angular/core';
import { ModalDialogComponent } from '@pedix-workspace/angular-ui-modal';
import { AnalyticsService } from '@pedix-workspace/pedixapp-core-services';
import { WhatsappLinkService } from '@pedix-workspace/angular-utils';
import { ApiV1_Establishment } from '@pedix-workspace/api-v1';
import { WorkingHoursMessageDetailsComponent } from '@pedix-workspace/pedixapp-shared-working-hours';
import { NgIf } from '@angular/common';
import {
  IconExternalLinkComponent,
  IconFacebookComponent,
  IconHomeComponent,
  IconInfoComponent,
  IconInstagramComponent,
  IconMapMarkerComponent,
  IconPhoneComponent,
  IconTiktokComponent,
  IconTwitterComponent,
  IconWebComponent,
  IconWhatsappComponent,
} from '@pedix-workspace/angular-ui-icons';
import { RouterLink } from '@angular/router';
import { TranslocoDirective } from '@ngneat/transloco';
import { environment } from '../../../../environments/environment';
import { AppRequestContextService } from '../../../app-request-context.service';
import { HereMapsStaticComponent } from '@pedix-workspace/angular-ui-here-maps';
import { StadiaMapsStaticComponent } from '@pedix-workspace/angular-ui-map';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TranslocoDirective,
    RouterLink,
    IconHomeComponent,
    IconInfoComponent,
    IconMapMarkerComponent,
    IconWebComponent,
    IconInstagramComponent,
    IconFacebookComponent,
    IconTwitterComponent,
    IconTiktokComponent,
    IconWhatsappComponent,
    IconPhoneComponent,
    IconExternalLinkComponent,
    NgIf,
    ModalDialogComponent,
    WorkingHoursMessageDetailsComponent,
    StadiaMapsStaticComponent,
  ],
})
export class SideMenuComponent {
  @ViewChild('logoutDialog')
  logoutDialog: ModalDialogComponent;

  @ViewChild('infoDialog')
  infoDialog: ModalDialogComponent;

  @ViewChild('locationDialog')
  locationDialog: ModalDialogComponent;

  @Input() displayCatalogMenu: boolean;

  @Output() navItemClick = new EventEmitter();

  landingUrl = `${environment.landingUrl}?utm_source=app&utm_medium=menu&utm_campaign=interest`;

  get establishment(): ApiV1_Establishment {
    return this.appRequestContextService.establishment;
  }

  get workingDaysEnabled() {
    return this.establishment?.workingDays.isActive;
  }

  get hasContactInfo() {
    return this.establishment.phone || this.establishment.additionalPhone;
  }

  get infoEnabled() {
    return this.workingDaysEnabled || this.hasContactInfo;
  }

  get hasAddressDetails() {
    return this.establishment.addressDetails;
  }

  get establishmentMapUrl() {
    const coordinates = `${this.establishment.addressDetails.coordinates.lat},${this.establishment.addressDetails.coordinates.lng}`;
    const zoomLevel = 15;

    return `https://www.google.com/maps?q=${coordinates}&zoom=${zoomLevel}`;
  }

  get whatsappLinkHttpTarget() {
    return this.whatsappLinkService.getWhatsappLinkHttpTarget();
  }

  get hasFeatureWhiteLabelBranding(): boolean {
    return this.establishment?.features.includes('WHITE_LABEL_BRANDING');
  }

  private appRequestContextService = inject(AppRequestContextService);
  private analytics = inject(AnalyticsService);
  private whatsappLinkService = inject(WhatsappLinkService);
  private cd = inject(ChangeDetectorRef);

  onNavItemClick(event) {
    this.navItemClick.emit();
  }

  onClickInfo() {
    this.infoDialog.open().catch(() => {});

    this.cd.markForCheck();
  }

  onClickLocation() {
    this.locationDialog.open().catch(() => {});

    this.cd.markForCheck();
  }

  onInterestInPedix() {
    this.analytics.logEvent('sidemenupedix_interest_clicked');
  }

  getLinkWhastapp() {
    return this.whatsappLinkService.getTrustedLink({
      phone: this.establishment.phone,
    });
  }
}
