import { isPlatformBrowser } from '@angular/common';
import { Component, OnInit, PLATFORM_ID, inject, signal } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { TranslocoDirective } from '@ngneat/transloco';
import { UntilDestroy } from '@ngneat/until-destroy';
import { AppPathLocationStrategy } from './app.path-location-strategy';
import { InstagramWarningComponent } from './features/instagram-issue/instagram-warning.component';
import { InstagramIssueService } from './utils/instagram-issue.service';

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [TranslocoDirective, RouterOutlet, InstagramWarningComponent],
})
export class AppComponent implements OnInit {
  private platformId = inject(PLATFORM_ID);
  private router = inject(Router);
  private pathLocation = inject(AppPathLocationStrategy);
  private instagramIssueService = inject(InstagramIssueService);

  showInstagramWarning = signal(false);

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.checkInstagramIssue();

      if (document.readyState === 'loading') {
        document.addEventListener('DOMContentLoaded', () => this.handleDeferUiInteraction());
      } else {
        this.handleDeferUiInteraction();
      }
    }
  }

  private async checkInstagramIssue(): Promise<void> {
    try {
      const hasInstagramIssue = await this.instagramIssueService.shouldShowInstagramWarning();
      if (hasInstagramIssue) {
        this.showInstagramWarning.set(true);
      }
    } catch (error) {
      console.error('Error checking Instagram issue:', error);
    }
  }

  private handleDeferUiInteraction() {
    window['APP_IS_READY'] = true;

    if (window['APP_LAST_CLICKED_ELEMENT']) {
      if (window['APP_LAST_CLICKED_ELEMENT'].tagName === 'A') {
        const anchorUrl = window['APP_LAST_CLICKED_ELEMENT'].getAttribute('href');
        const anchorUrlWithoutSlug = anchorUrl.replace(this.pathLocation.getBaseHref(), '');

        this.router.navigate(['/' + anchorUrlWithoutSlug]);
      } else {
        window['APP_LAST_CLICKED_ELEMENT'].click();
      }

      setTimeout(() => {
        const overlay = document.querySelector('#appPrebootOverlay');
        if (overlay) {
          overlay.remove();
        }
      }, 500);
    }
    document.body.removeEventListener('click', window['APP_CLICK_CAPTURE_LISTENER']);
  }
}

